
.live-script {
  box-sizing: border-box;
  flex: 6;
  font-size: 14px;
  color: #fff;
  background-color: #0c0e3f;
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .head-box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      width: 100%;
      font-size: 24px;
      .title {
        margin: 0 24px;
        margin-top: -2px;
      }
      .decorate {
        width: 41px;
        height: 15px;
        vertical-align: middle;
      }
    }
    .content {
      position: relative;
      flex: 1;
      display: flex;
      justify-content: center;
      overflow: hidden;
      .live-ing-script {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 85%;
        height: 100%;
        font-size: 24px;
        line-height: 72px;
        letter-spacing: 1px;
        word-break: break-all;
        overflow: hidden;
        .text {
          transform: translateY(-30px);
        }
      }
      .empty {
        position: absolute;
        top: 40%;
        font-size: 20px;
        color: #aaa;
      }
    }
  }
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
